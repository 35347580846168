import { render, staticRenderFns } from "./examination-tab.vue?vue&type=template&id=5b9a0bd2&scoped=true"
import script from "./examination-tab.vue?vue&type=script&lang=js"
export * from "./examination-tab.vue?vue&type=script&lang=js"
import style0 from "./examination-tab.vue?vue&type=style&index=0&id=5b9a0bd2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9a0bd2",
  null
  
)

export default component.exports